import React from "react";
import styled from "styled-components";

import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import {
  WhatYouGetAsNerdWalletContent,
  NerdwalletFaqData,
  ExpertInPocketContentNerdWalletSMB,
  NerdwalletSMBFaqData,
  WhatYouGetAsNerdWalletSMBContent,
} from "../../data/homeData";

import Image from "../common/Image";
import Layout from "../layouts/nerdwalletLayout";
import ExpertInPocket from "../home/ExpertInPocket";
import NerdwalletFaq from "./NerdwalletFaq";
import NerdwalletHero from "./NerdwalletHero";
import NerdwalletFooter from "../nerdwallet/NerdwalletFooter";
import NerdwalletFeedback from "./NerdwalletFeedback";
import NerdWalletNoWorries from "./NerdWalletNoWorries";
import NerdwalletWhatWeCover from "./NerdwalletWhatWeCover";
import NerdwalletStillQuestions from "./NerdwalletStillQuestions";
import NerdWalletPaymentDetails from "./NerdWalletPaymentDetails";
import { NerdwalletHowUpriseWorks } from "./NerdwalletHowUpriseWorks";
import { isNonEmpty, parseReqParams } from "../../utils/commonUtil";
import {
  CLAIR_SIGNUP_CODE,
  NERDWALLET_SMB_LOGIN,
  NERDWALLET_SMB_SIGNUP,
  QUERY_PARAMS,
} from "../../utils/commonConst";
import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";
import NerdwalletHeader from "../nerdwallet/NerdwalletHeader";

const nerdwalletCoachLogo = "nerdwallet/nerdwallet_coach_logo.png";

export default function NerdwalletSMBPartnership() {
  const signupCodeReqParamValue = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);
  const signupCode = `${CLAIR_SIGNUP_CODE}${
    isNonEmpty(signupCodeReqParamValue) ? ` - ${signupCodeReqParamValue}` : ""
  }`;
  const expertInPocketHeader = (
    <>
      Feel the confidence of{" "}
      <span style={{ color: Colors.nerdwalletBlue }}>
        having an expert in your back pocket
      </span>
      .
    </>
  );
  return (
    <Layout includeHeader={false} includeFooter={false}>
      {/* {process.env.GATSBY_SHOW_BANNER === "true" && <Banner />} */}
      <WrapperBG>
        <NerdwalletHeader
          hideMoreInfo={true}
          customLogoDesktop={nerdwalletCoachLogo}
          loginUrl={NERDWALLET_SMB_LOGIN}
          signupUrl={NERDWALLET_SMB_SIGNUP}
        />
        <HeroWrapper>
          <NerdwalletHero signupCode={signupCode} />
          <Image className="HeroImage" src="nerdwallet-smb/hero-illust.png" />
          {/* <TrustedBySectionWrapper>
            <LogoBar content={NerdWalletTrustedBy} signupCode={signupCode} />
          </TrustedBySectionWrapper> */}
        </HeroWrapper>
        <NerdWalletNoWorries whatYouGet={WhatYouGetAsNerdWalletSMBContent} />
        <ExpertInPocketWrapper>
          <ExpertInPocket
            expertInPocketData={ExpertInPocketContentNerdWalletSMB}
            headerTextComponent={expertInPocketHeader}
          />
        </ExpertInPocketWrapper>
      </WrapperBG>
      <TrustWrapperBG>
        <HowUpriseWorksWrapper>
          <NerdwalletHowUpriseWorks />
        </HowUpriseWorksWrapper>
        <CustomerFeedbackWrapper>
          <NerdwalletFeedback />
        </CustomerFeedbackWrapper>
      </TrustWrapperBG>
      <WhatWeCoverWrapper>
        <NerdwalletWhatWeCover />
      </WhatWeCoverWrapper>
      <FaqWrapper>
        <NerdWalletPaymentDetails />
        <NerdwalletFaq content={NerdwalletSMBFaqData} />
      </FaqWrapper>
      <StillQuestionsWrapper>
        <NerdwalletStillQuestions />
      </StillQuestionsWrapper>
      <FooterWrapper>
        <NerdwalletFooter />
      </FooterWrapper>
    </Layout>
  );
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .HeroImage {
    position: absolute !important;
    width: 35vw;
    right: -7vw;
    top: 132px;

    @media (max-width: ${Breakpoints.sm}) {
      position: relative !important;
      width: 100vw;
      right: 0vw;
      top: 0px;
    }
  }
`;

const WrapperBG = styled.div`
  position: relative;
  background: linear-gradient(
    ${Colors.white},
    ${Colors.nerdwalletBackgroundBlue}
  );
`;

const CustomerFeedbackWrapper = styled.div`
  @media (max-width: ${Breakpoints.sm}) {
    padding-top: ${Spacing.sm};
  }
`;

const TrustWrapperBG = styled.div`
  background-color: ${Colors.white};
`;

const FooterWrapper = styled.div`
  background-color: white;
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const TrustedBySectionWrapper = styled.div`
  margin-top: 90px;

  @media (max-width: ${Breakpoints.lg}) {
    margin-top: ${Spacing.xxl};
  }
`;

const HowUpriseWorksWrapper = styled.div`
  padding: ${Spacing.xxxxxl};
  padding-top: 160px;
  padding-bottom: 160px;
  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.xl} 0;
  }
`;

const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 125px 0 ${Spacing.xxxxxl};
  background: linear-gradient(
    to right,
    ${Colors.white},
    ${Colors.nerdwalletBackgroundBlue}
  );

  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.xl} 0;
  }
`;

const ExpertInPocketWrapper = styled.div`
  background-color: ${Colors.nerdwalletBackgroundBlue};
  margin-top: ${Spacing.xxxxl};
`;

const WhatWeCoverWrapper = styled.div``;

const StillQuestionsWrapper = styled.div``;
