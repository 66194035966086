import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import HelperText from "../common/HelperText";
import {
  HEADER_LEVEL,
  NERDWALLET_SIGNUP,
  NERDWALLET_SMB_SIGNUP,
  STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK,
} from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontSizes,
  Spacing,
} from "../../styles/styleConstants";
import { navigateTo } from "../../utils/commonUtil";

function NerdWalletNoWorries(props) {
  const { whatYouGet } = props ?? {};
  const { title, data } = whatYouGet;

  return (
    <WhatYouGetWrapper>
      <HeaderText level={HEADER_LEVEL.LEVEL_2}>{title}</HeaderText>
      <WhatYouGetCardWrapper>
        {data.map((element) => {
          return (
            <WhatYouGetCard>
              <ImageWrapper>
                <Image
                  src={element.logo_path}
                  style={{
                    minWidth: "64px",
                    maxWidth: "64px",
                  }}
                />
              </ImageWrapper>
              <WhatYouGetDetails>
                <HeaderText
                  level={HEADER_LEVEL.LEVEL_4}
                  style={{
                    margin: `${Spacing.xs} 0`,
                    fontSize: "20px !important",
                  }}
                >
                  {element.title}
                </HeaderText>
                <HelperText
                  style={{ fontSize: FontSizes.md, lineHeight: "24px" }}
                >
                  {element.detailText}
                </HelperText>
              </WhatYouGetDetails>
            </WhatYouGetCard>
          );
        })}
      </WhatYouGetCardWrapper>
      <CtaButton
        onClick={(evt) => {
          navigateTo(STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK);
          evt.preventDefault();
          evt.stopPropagation();
        }}
        ctaType="primary"
        style={{
          backgroundColor: Colors.nerdwalletBlue,
          marginTop: Spacing.md,
          height: "48px",
        }}
        color={Colors.white}
      >
        Book your free call
      </CtaButton>
    </WhatYouGetWrapper>
  );
}

export default NerdWalletNoWorries;

const WhatYouGetWrapper = styled.div`
  margin-top: 45px;
  margin-bottom: 45px;
  width: 1220px;
  margin-left: calc(50% - 610px);
  @media (max-width: ${Breakpoints.lg}) {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 90%;
    margin-left: 5%;
  }
`;

const WhatYouGetCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${Spacing.xs} 0;
  flex-wrap: wrap;
  margin-top: ${Spacing.lg};
  gap: 20px;

  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    gap: ${Spacing.lg};
  }
`;

const WhatYouGetCard = styled.div`
  display: flex;
  align-items: center;
  padding: ${Spacing.md} 0;
  background-color: #fff;
  width: 49%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px;

  @media (max-width: ${Breakpoints.sm}) {
    padding: ${Spacing.lg};
  }
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: ${Breakpoints.lg}) {
    margin: auto;
    width: 100%;
  }
`;

const WhatYouGetDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${Spacing.md};

  @media (max-width: ${Breakpoints.sm}) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  margin-left: ${Spacing.md};

  @media (max-width: ${Breakpoints.sm}) {
    margin-left: 0;
  }
`;
