import React from "react";
import { Container, Col } from "react-bootstrap";
import styled from "styled-components";

import CtaButton from "../common/CtaButton";
import HelperText from "../common/HelperText";
import { navigateTo } from "../../utils/commonUtil";
import {
  NERDWALLET_SIGNUP,
  NERDWALLET_SMB_SIGNUP,
  STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK,
} from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";

export default function NerdWalletHero() {
  return (
    <HeroWrapper>
      <Container className="partner-hero-container" style={{ marginLeft: 0 }}>
        <Col className="text-container">
          <Title>
            Financial planning for <span>small business owners</span> like you
          </Title>
          <HeroDescription>
            Get personalized financial guidance from a Certified Financial
            Planner™ small business expert. For your business and for you.
          </HeroDescription>
          <CtaWrapper>
            <CtaButton
              onClick={(evt) => {
                navigateTo(STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              style={{ backgroundColor: Colors.nerdwalletBlue, height: "54px" }}
              color={Colors.white}
            >
              Book your free call
            </CtaButton>
          </CtaWrapper>
          <HelperText
            style={{
              color: Colors.neutralMedDark,
              marginTop: Spacing.md,
              fontSize: FontSizes.lg,
              lineHeight: "30px",
            }}
          >
            <b>The first call is free.</b> <br />
            $25/month afterwards, billed quarterly.  <br />
            30-day 100% money back guarantee.
          </HelperText>
        </Col>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  width: 100% !important;
  display: flex;
  margin-top: 24px;

  .partner-hero-container {
    width: 50vw;
    padding-right: 0 !important;
    margin-top: 90px;

    @media (max-width: ${Breakpoints.sm}) {
      width: 100vw;
      margin-top: 0px;
    }
  }

  .text-container {
    padding-left: 15vw !important;
    display: flex;
    flex-direction: column;

    @media (max-width: 1620px) {
      padding-left: 13vw !important;
    }
    @media (max-width: 1440px) {
      padding-left: 10vw !important;
    }
    @media (max-width: ${Breakpoints.lg}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.md}) {
      padding-left: 13vw !important;
    }
    @media (max-width: ${Breakpoints.sm}) {
      margin-right: ${Spacing.md} !important;
      padding-left: ${Spacing.md} !important;
    }
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin: 24px 0px;
    flex-direction: column;

    .partner-hero-container {
      width: 100%;
      padding: 0 !important;
    }
  }
`;

const CtaWrapper = styled.div``;

const Title = styled.div`
  font-family: ${FontFamilies.base};
  font-style: normal;
  font-weight: ${FontWeights.normal};
  font-size: 32px;
  color: ${Colors.almostBlack};

  span {
    color: ${Colors.nerdwalletBlue};
    font-weight: 500;
  }

  @media only screen and (min-width: ${Breakpoints.md}) {
    line-height: 64.8px;
    font-size: 54px;
  }
`;

const HeroDescription = styled.div`
  font-family: ${FontFamilies.base};
  font-style: normal;
  font-weight: ${FontWeights.normal};
  font-size: 20px;
  line-height: 30px;
  color: ${Colors.almostBlack};

  margin-top: 45px;
  margin-bottom: 45px;
`;
