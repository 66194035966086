import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import HeaderText from "../common/HeaderText";
import {
  HEADER_LEVEL,
  NERDWALLET_SIGNUP,
  NERDWALLET_SMB_SIGNUP,
  STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK,
} from "../../utils/commonConst";
import {
  Breakpoints,
  Colors,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";
import { navigateTo } from "../../utils/commonUtil";

const CardsData = [
  {
    mainIcon: "",
    header: "Free financial advising call",
    showPricing: false,
    borderColor: Colors.white,
    backgroundColor: Colors.white,
    icon: "common/tick_black.png",
    data: [
      "One 45-minute video call with a Certified Financial Planner™ SMB expert",
      "Unbiased advice personalized to your business and/or your personal finances",
      "Follow-up note from your advisor with a summary of action items from your conversation",
    ],
    btn: "Book your free call",
  },
  {
    mainIcon: "partner/ic-valueProp3@2x.png",
    header: "",
    showPricing: true,
    borderColor: Colors.white,
    backgroundColor: Colors.white,
    icon: "common/tick_black.png",
    data: [
      "Comprehensive, personalized financial planning for your business and you",
      "Unlimited messaging with your advisor",
      "Continuous monitoring of your finances for new opportunities",
      "Add-on additional video calls with your advisor for $75 per call",
    ],
    btn: "Book your free call",
  },
  {
    mainIcon: "",
    header: "Traditional financial advisors",
    showPricing: false,
    borderColor: Colors.neutralMedium,
    backgroundColor: Colors.neutralMedLight,
    icon: "home/red_close.png",
    data: [
      "Typically cost thousands of dollars per year",
      "Only available to high net worth individuals",
      "Are low-tech",
    ],
    btn: "",
  },
];

const NerdWalletPaymentDetails = () => {
  return (
    <Container>
      <Wrapper id="pricing-details">
        <HeaderText level={HEADER_LEVEL.LEVEL_2}>
          <TitleStrong>
            You don’t need to pay more for the best financial advice
          </TitleStrong>
        </HeaderText>
        <CardsWrapper>
          {CardsData.map((card, index) => {
            const {
              backgroundColor,
              borderColor,
              btn,
              data,
              header,
              icon,
              mainIcon,
              showPricing,
            } = card;
            return (
              <Card
                key={index}
                backgroundColor={backgroundColor}
                borderColor={borderColor}
              >
                <HeaderWrapper>
                  <Image
                    src={mainIcon}
                    style={{
                      minWidth: "64px",
                      maxWidth: "64px",
                    }}
                  />
                  {header && (
                    <HeaderText level={HEADER_LEVEL.LEVEL_5}>
                      <HeaderStrong>{header}</HeaderStrong>
                    </HeaderText>
                  )}
                  {showPricing && (
                    <Pricing>
                      <TopLevelPrice>
                        <HeaderText level={HEADER_LEVEL.LEVEL_3}>
                          $25 /
                        </HeaderText>
                        <HeaderText level={HEADER_LEVEL.LEVEL_6}>
                          {" "}
                          mo
                        </HeaderText>
                      </TopLevelPrice>
                      <HeaderText level={HEADER_LEVEL.LEVEL_6}>
                        billed quarterly
                      </HeaderText>
                    </Pricing>
                  )}
                </HeaderWrapper>
                <DetailsWrapper>
                  {data.map((text, index) => {
                    return (
                      <Detail key={index}>
                        <Image
                          src={icon}
                          style={{
                            minWidth: "20px",
                            maxWidth: "20px",
                            height: "20px",
                          }}
                        />
                        <HeaderText level={HEADER_LEVEL.BODY_COPY}>
                          {text}
                        </HeaderText>
                      </Detail>
                    );
                  })}
                </DetailsWrapper>
                {btn && (
                  <CtaButton
                    onClick={(e) => {
                      navigateTo(STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    background={Colors.nerdwalletBlue}
                    color={Colors.white}
                    style={{ height: "48px", "margin-top": "auto" }}
                  >
                    {btn}
                  </CtaButton>
                )}
              </Card>
            );
          })}
        </CardsWrapper>
      </Wrapper>
    </Container>
  );
};

export default NerdWalletPaymentDetails;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-bottom: 160px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    margin-bottom: auto;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: ${Spacing.xxl} auto;

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    gap: ${Spacing.lg};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-center;
  min-height: 84px;
`;
// padding: ${Spacing.lg};
const Card = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor ?? props.backgroundColor};
  border-radius: 8px;
  box-shadow: -4px 11px 14.5px 0px #0000001a;

  gap: ${Spacing.md};
  min-height: 560px;
  padding: 32px;
  padding-top: 24px;
  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    flex-direction: column;
  }
`;

const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TopLevelPrice = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.sm};
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.sm};
`;

const HeaderStrong = styled.strong`
  font-size: 30px;
  font-weight: ${FontWeights.bolder};
`;

const TitleStrong = styled.strong`
  font-weight: ${FontWeights.bolder};
  font-size: 32px;
`;
