import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import StarImage from "../common/StarImage";
import { headerStyle } from "../common/HeaderText";
import {
  HEADER_LEVEL,
  NERDWALLET_SIGNUP,
  NERDWALLET_SMB_SIGNUP,
  STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK,
} from "../../utils/commonConst";
import { navigateTo } from "../../utils/commonUtil";

import {
  Breakpoints,
  Colors,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../styles/styleConstants";

const feedbackData = [
  {
    content: (
      <>
        <HighlightedText>
          I swear my jaw dropped every page I went to. I was truly amazed.
        </HighlightedText>
        <br />
        Every recommendation in my plan was SO personalized!
      </>
    ),
    author: "Grace, small business owner",
  },
  {
    content: (
      <>
        I was scared to see my plan but I love it. You have made it seem easy.{" "}
        <HighlightedText>I should have done this sooner.</HighlightedText>
      </>
    ),
    author: "Kate, tech business owner",
  },
  {
    content: (
      <>
        You've given me <br />
        <HighlightedText>more tax advice</HighlightedText> <br />
        than my tax guys have in 3 years.
      </>
    ),
    author: "Samuel, consultant",
  },
];

function HighlightedText(props) {
  const { children } = props ?? {};
  return <HighlightedTextStrong>{children}</HighlightedTextStrong>;
}

export default function NerdwalletFeedback() {
  return (
    <Container>
      <CustomerFeedbackWrapper>
        <div style={{ zIndex: 1 }}>
          <FeedbackHeader level={HEADER_LEVEL.LEVEL_1}>
            Uprise is <span>trusted</span> and <span>loved</span> by thousands
          </FeedbackHeader>
          <ProductHuntStar>
            <div className="desktop-stars">
              <StarImage style={{ margin: "0px 4px" }} />
              <StarImage style={{ margin: "0px 4px" }} />
              <StarImage style={{ margin: "0px 4px" }} />
              <StarImage style={{ margin: "0px 4px" }} />
              <StarImage style={{ margin: "0px 4px" }} />
            </div>
            <div className="mobile-stars">
              <StarImage style={{ margin: "0px 4px" }} />
            </div>
            <span>
              <b>4.7 / 5 stars</b> on <u>Product Hunt</u>
            </span>
          </ProductHuntStar>
          <FeedbackCardWrapper>
            {feedbackData.map((feedback, idx) => {
              return (
                <FeedbackDetailsWrapper>
                  <FeedbackCard key={`feedback-card-${idx}`}>
                    {feedback.content}
                  </FeedbackCard>
                  <AuthorText>{feedback.author}</AuthorText>
                  <span>
                    <StarImage style={{ margin: "0px 4px 0px 24px" }} />
                    <StarImage style={{ margin: "0px 4px" }} />
                    <StarImage style={{ margin: "0px 4px" }} />
                    <StarImage style={{ margin: "0px 4px" }} />
                    <StarImage style={{ margin: "0px 4px" }} />
                  </span>
                </FeedbackDetailsWrapper>
              );
            })}
          </FeedbackCardWrapper>
          <ProductHuntLogo>
            <Image
              src="common/product-hunt-logo.png"
              style={{
                height: "48px",
                width: "216px",
                margin: `${Spacing.xxl} auto`,
              }}
            />
          </ProductHuntLogo>
          <CtaWrapper className="desktop-cta">
            <CtaButton
              onClick={(evt) => {
                navigateTo(STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              style={{ backgroundColor: Colors.nerdwalletBlue, height: "54px" }}
              color={Colors.white}
            >
              Book your free call
            </CtaButton>
            <CtaButton
              onClick={(evt) => {
                document.getElementById("pricing-details").scrollIntoView({
                  behavior: "smooth",
                });
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="secondary"
              style={{ height: "54px" }}
              color={Colors.almostBlack}
            >
              Explore pricing
            </CtaButton>
          </CtaWrapper>
          <CtaWrapper className="mobile-cta">
            <CtaButton
              onClick={(evt) => {
                navigateTo(STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK);
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="primary"
              style={{ backgroundColor: Colors.nerdwalletBlue, height: "54px" }}
              color={Colors.white}
              isStretchToFullWidth
            >
              Book your free call
            </CtaButton>
            <CtaButton
              onClick={(evt) => {
                document.getElementById("pricing-details").scrollIntoView({
                  behavior: "smooth",
                });
                evt.preventDefault();
                evt.stopPropagation();
              }}
              ctaType="secondary"
              style={{ height: "54px" }}
              color={Colors.almostBlack}
              isStretchToFullWidth
            >
              Explore pricing
            </CtaButton>
          </CtaWrapper>
        </div>
        <BgImageWrapper>
          <Image src="home/trusted_by/nw_trusted_bg_2.png" />
        </BgImageWrapper>
      </CustomerFeedbackWrapper>
    </Container>
  );
}

const CustomerFeedbackWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .testimonial {
    min-height: 72vh;
    max-height: 80vh;

    @media (max-width: ${Breakpoints.md}) {
      min-height: 80vh;
    }
  }

  div {
    @media (min-width: ${Breakpoints.sm}) {
      .mobile-cta {
        display: none;
      }
    }
    @media (max-width: ${Breakpoints.sm}) {
      .desktop-cta {
        display: none;
      }
    }
  }
`;

const FeedbackHeader = styled.div`
  ${headerStyle[HEADER_LEVEL.LEVEL_1]};

  span {
    color: ${Colors.nerdwalletBlue};
  }

  @media (max-width: ${Breakpoints.md}) {
    text-align: center;
  }
`;

const ProductHuntStar = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 20px;
  margin: ${Spacing.md} 0px ${Spacing.sm};
  color: ${Colors.almostBlack};

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: row;

    .desktop-stars {
      display: none;
    }
  }

  @media (min-width: ${Breakpoints.sm}) {
    .mobile-stars {
      display: none;
    }
  }
`;

const FeedbackCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 32px 0px 0px;

  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    padding: 8px;
  }
`;

const FeedbackDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${Breakpoints.sm}) {
    &:not(:last-child) {
      margin-bottom: ${Spacing.lg};
    }
  }
`;

const FeedbackCard = styled.div`
  width: 75%;
  height: 180px;
  padding-left: 24px;
  font-size: ${FontSizes.lg};
  border-left: 3px solid ${Colors.nerdwalletPurple};

  @media (max-width: ${Breakpoints.sm}) {
    width: 100%;
    height: auto;
  }
`;

const AuthorText = styled.span`
  font-size: ${FontSizes.md};
  font-style: italic;
  margin: ${Spacing.lg} ${Spacing.lg} 0;
  color: ${Colors.neutralDark};

  @media (max-width: ${Breakpoints.sm}) {
    margin: ${Spacing.md} ${Spacing.lg} 0;
  }
`;

const HighlightedTextStrong = styled.strong`
  color: ${Colors.nerdwalletPurple};
  font-weight: ${FontWeights.bold};
`;

const ProductHuntLogo = styled.div`
  display: flex;
  margin: auto;
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.lg};
  margin: auto;
  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BgImageWrapper = styled.div`
  position: absolute;
  height: 500px;
  width: 500px;
  z-index: 0;
  right: 0;
  @media (max-width: ${Breakpoints.sm}) {
    top: 30vh;
    left: 0;
  }
`;
