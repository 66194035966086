import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { WhatWeCoverContent } from "../../data/homeData";
import titleBackground from "../../images/nerdwallet/what_we_cover_title_background.png";

import Image from "../common/Image";
import CtaButton from "../common/CtaButton";
import {
  NERDWALLET_SIGNUP,
  NERDWALLET_SMB_SIGNUP,
  OTHER_APP_PAGES,
  STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK,
} from "../../utils/commonConst";
import { getAppUrlByModule, navigateTo } from "../../utils/commonUtil";
import {
  Breakpoints,
  Colors,
  FontFamilies,
  FontSizes,
  FontWeights,
} from "../../styles/styleConstants";
import Carousel from "react-multi-carousel";

const contentData = [
  {
    title: "Start",
    headerBG: "#6298CC",
    body: [
      <>
        <span>Registering</span> your business so you can legally operate
      </>,
      <>
        Choosing <span>the right business structure</span>, from S-Corp to
        C-Corp
      </>,
      <>
        Separating <span>business and personal</span> finances
      </>,
      <>
        Keeping track of <span>cash flow</span> to pay your bills and plan for
        expansion
      </>,
      <>
        <span>Protecting your business</span> with the right types of insurance
        (e.g., liability, property, workers' compensation)
      </>,
      <>
        Rollovers as Business Startups <span>(ROBs)</span>
      </>,
      <>
        Getting <span>SBA loans</span> from the government to fund your business
      </>,
      <>
        <span>Bookkeeping</span> basics to manage your business finances and
        file taxes accurately
      </>,
    ],
  },
  {
    title: "Survive",
    headerBG: "#005FB9",
    body: [
      <>
        Setting up a <span>Solo 401k/SEP IRA</span> to save for retirement
      </>,
      <>
        Choosing <span>S Corp</span> status so you can save money on
        self-employment taxes
      </>,
      <>
        Paying yourself <span>reasonable compensation</span>
      </>,
      <>
        Taking advantage of <span>QBI</span> to lower your taxable income
      </>,
      <>
        Claiming <span>R&D tax credits</span>
      </>,
      <>
        Getting <span>short-term loans</span> to cover immediate business needs
      </>,
      <>
        Making <span>estimated tax payments</span> to avoid penalties
      </>,
      <>
        Offering <span>employee benefits</span> for yourself and employees
      </>,
      <>
        Strategies to manage the limits on state and local tax{" "}
        <span>(SALT)</span> deductions
      </>,
      <>
        Understanding the tax implications of <span>hiring family members</span>
      </>,
    ],
  },
  {
    title: "Thrive",
    headerBG: "#02519D",
    body: [
      <>
        Setting up a <span>401(k)/SIMPLE IRA</span> for you and your team
      </>,
      <>
        Buying <span>depreciable assets</span> for your business
      </>,
      <>
        Deciding when and how to <span>distribute profits</span> from your
        business
      </>,
      <>
        <span>Hiring employees</span> to expand your business
      </>,
      <>
        <span>Acquiring other</span> business to grow your operations
      </>,
      <>
        Taking advantage of <span>Qualified Small Business Stock (QSBS)</span>
      </>,
      <>
        Planning for <span>business succession</span>
      </>,
      <>
        Creating an <span>exit strategy</span> for selling your business
      </>,
      <>
        Using the <span>Augusta rule</span> to rent your home to your business
      </>,
      <>
        Setting up a <span>structured installment</span> sale to reduce tax
        burdens
      </>,
    ],
  },
  {
    title: "And we cover your whole personal life as well",
    headerBG: "#033462",
    body: [
      <>
        Scanning your finances for <span>small business tax optimizations</span>
      </>,
      <>
        Making sure your <span>cash flow</span> is in a good place to cover
        taxes, inventory, and expansion
      </>,
      <>
        Covering your family:{" "}
        <span>life insurance, wills, and estate planning</span>
      </>,
      <>
        Tracking you and your family&apos;s financial goals and budgeting for
        them
      </>,
      <>Managing your credit and debt, and having enough emergency savings</>,
      <>
        <span>Allocating your funds</span> between paying down debt, holding
        cash, and investing
      </>,
      <>
        Investing for <span>retirement</span>
      </>,
    ],
  },
];

export default function NerdwalletWhatWeCover() {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(null);
  return (
    <NerdwalletCoverContainer>
      <HeaderWrapper>
        <HeaderTitle>
          We cover you and <span>the whole lifecycle</span> of your business
        </HeaderTitle>
        <HeaderHelper>Specific topics at every stage.</HeaderHelper>
      </HeaderWrapper>
      <CarouselWrapper>
        <Carousel
          ref={carouselRef}
          afterChange={(previousSlide, { currentSlide }) => {
            setCurrentSlide(currentSlide);
          }}
          draggable={true}
          infinite={true}
          arrows={false}
          autoPlay={true}
          partialVisbile={false}
          responsive={{
            desktop: { breakpoint: { max: 5000, min: 576 }, items: 2 },
            mobile: { breakpoint: { max: 576, min: 0 }, items: 1 },
          }}
        >
          {contentData.map((ele, i) => {
            return (
              <ContentWrapper key={`what-cover-carousel-${i}`}>
                <ContentHeader headerBG={ele.headerBG}>
                  {ele.title}
                </ContentHeader>
                <ContentBody>
                  <BulletList>
                    {ele.body.map((text, j) => {
                      return (
                        <BulletItem key={`what-cover-bullet-${i}-${j}`}>
                          {text}
                        </BulletItem>
                      );
                    })}
                  </BulletList>
                </ContentBody>
              </ContentWrapper>
            );
          })}
        </Carousel>
      </CarouselWrapper>
      <SelectorsWrapper>
        {contentData.map((ele, i) => {
          return (
            <Selector
              key={`what-cover-selector-${i}`}
              onClick={() => {
                const missingSlides = i - (currentSlide % 4);
                carouselRef.current?.goToSlide(currentSlide + missingSlides);
              }}
              selected={currentSlide % 4 === i}
            />
          );
        })}
      </SelectorsWrapper>
      <CtaWrapper>
        <CtaButton
          onClick={(evt) => {
            navigateTo(STILL_QUESTIONS_NERDWALLET_SMB_CALENDLY_LINK);
            evt.preventDefault();
            evt.stopPropagation();
          }}
          ctaType="primary"
          style={{ backgroundColor: Colors.nerdwalletBlue, height: "54px" }}
          color={Colors.white}
        >
          Book your free call
        </CtaButton>
      </CtaWrapper>
    </NerdwalletCoverContainer>
  );
}

const NerdwalletCoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background-color: ${Colors.nerdwalletBackgroundBlue};

  @media (max-width: ${Breakpoints.sm}) {
    padding: 16px;
    width: 100vw;
    flex-direction: column;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  height: 581px;
  width: 628px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 8px 0px 5.5px 0px #0000001a;

  @media (max-width: ${Breakpoints.sm}) {
    width: 90vw;
    height: 100%;
  }
`;

const ContentHeader = styled.div`
  background-color: ${(props) => props.headerBG};
  color: #f5f8ff;
  font-weight: ${FontWeights.bolder};
  font-size: 26px;
  font-family: ${FontFamilies.base};
  height: 64px;
  padding-left: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media (max-width: ${Breakpoints.sm}) {
    height: auto;
    min-height: 64px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 36px;
  }
`;
const ContentBody = styled.div`
  @media (max-width: ${Breakpoints.sm}) {
    margin-bottom: 24px;
  }
`;
const SelectorsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 447px;
  justify-content: space-between;
  margin-top: 72px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 90vw;
  }
`;

const Selector = styled.div`
  width: 102px;
  height: 13px;
  background: transparent;
  border-radius: 11px;
  border: 1.8px solid #015fb9;
  ${(props) => props.selected && "background-color: #6298CC;"}

  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 24px;
    width: 22%;
    height: 24px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 80px;

  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 24px;
    height: auto;
  }
`;
const HeaderTitle = styled.div`
  font-size: 44px;
  font-family: ${FontFamilies.base};
  font-weight: ${FontWeights.bold};
  color: ${Colors.nerdwalletBlue};

  span {
    background-color: ${Colors.nerdwalletBlue};
    color: ${Colors.white};
    padding: 4px;
  }
  @media (max-width: ${Breakpoints.sm}) {
    font-size: 32px;
  }
`;
const HeaderHelper = styled.div`
  font-size: 25px;
  font-family: ${FontFamilies.base};
  font-weight: ${FontWeights.normal};
  color: ${Colors.nerdwalletBlue};
  font-style: italic;
  margin-bottom: 48px;
`;

const BulletList = styled.ul`
  width: 539px;
  margin-top: 24px;

  @media (max-width: ${Breakpoints.sm}) {
    width: 90%;
  }
`;
const BulletItem = styled.li`
  margin-top: 16px;
  color: ${Colors.nerdwalletBlue};
  font-weight: ${FontWeights.normal};
  font-size: ${FontSizes.md};
  margin-left: 12px;
  span {
    font-weight: ${FontWeights.bold};
  }
`;

const CarouselWrapper = styled.div`
  height: 581px;
  width: 90vw;
  margin-left: 5%;

  @media (max-width: ${Breakpoints.sm}) {
    height: auto;
    width: 100%;
  }
`;

const CtaWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 80px;

  @media (max-width: ${Breakpoints.sm}) {
    margin-bottom: 24px;
  }
`;
